<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Вопрос</VToolbarTitle>

            <VSpacer />

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="warning"
                  small
                  class="mr-4"
                  :to="{
                    name : Names.R_TALENT_TEST_TOPIC_QUESTION_EDIT,
                    params : {
                      testId,
                      topicId,
                      questionId
                    }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>

              <span>Редактирование вопроса</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="red"
                  small
                  class="mr-4"
                  v-bind="attrs"
                  @click="removeQuestion(testId, questionId)"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удалить вопрос</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  depressed
                  color="blue"
                  small
                  class="mr-4"
                  :to="{
                    name : Names.R_TALENT_TEST_TOPIC_QUESTION_ANSWERS,
                    params : {
                      testId,
                      topicId,
                      questionId
                    }
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-list
                  </VIcon>
                </VBtn>
              </template>

              <span>Список ответов</span>
            </VTooltip>
          </VToolbar>

          <VCardText>
            <VRow>
              <VCol cols="6">
                <VExpansionPanels
                  accordion
                  flat
                >
                  <VExpansionPanel>
                    <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                    <VExpansionPanelContent>
                      <code
                        class="elevation-0 d-block"
                        style="white-space: pre"
                      >
                        {{ question }}
                      </code>
                    </VExpansionPanelContent>
                  </VExpansionPanel>
                </VExpansionPanels>

                <VDivider class="my-4" />

                <div
                  style="border: 1px solid lightgray"
                  class="rounded pa-2 mb-2"
                >
                  <h5>
                    Название
                  </h5>
                  <p class="mb-0">
                    {{ question.title }}
                  </p>
                </div>
                <div
                  style="border: 1px solid lightgray"
                  class="rounded pa-2 mb-2"
                >
                  <h5>
                    Уровень
                  </h5>
                  <p class="mb-0">
                    {{ question.level }}
                  </p>
                </div>

                <div>
                  <div
                    style="border: 1px solid lightgray"
                    class="rounded pa-2 mb-2"
                  >
                    <h5>
                      Описание
                    </h5>
                    <p class="mb-0">
                      {{ question.originalDescription }}
                    </p>
                  </div>
                  <VExpansionPanels
                    v-if="question.description"
                    class="my-4"
                  >
                    <VExpansionPanel>
                      <VExpansionPanelHeader>Описание (HTML)</VExpansionPanelHeader>
                      <VExpansionPanelContent>
                        <!-- eslint-disable vue/no-v-html -->
                        <VCard
                          class="pa-2"
                          outlined
                          tile
                          v-html="question.description"
                        />
                        <!-- eslint-enable vue/no-v-html -->
                      </VExpansionPanelContent>
                    </VExpansionPanel>
                  </VExpansionPanels>
                </div>

                <div
                  style="border: 1px solid lightgray"
                  class="rounded pa-2 mb-2"
                >
                  <h5>
                    Комментарий к верному ответу
                  </h5>
                  <p class="mb-0">
                    {{ question.rightAnswerComment }}
                  </p>
                </div>

                <div
                  style="border: 1px solid lightgray"
                  class="rounded pa-2 mb-2"
                >
                  <h5>
                    Изображения
                  </h5>

                  <div>
                    <template
                      v-for="(image, imageIndex) in question.questionImages"
                    >
                      <!-- eslint-disable vue/no-v-html -->
                      <VCard
                        :key="`image_url-${imageIndex}`"
                        outlined
                        tile
                        class="mb-3"
                        v-html="image.imageUrl"
                      />
                      <!-- eslint-enable vue/no-v-html -->

                      <VCard
                        :key="`image_url_html-${imageIndex}`"
                        outlined
                        tile
                        class="mb-3"
                        v-text="image.imageUrlHtml"
                      />

                      <!-- eslint-disable vue/no-v-html -->
                      <VCard
                        :key="`image-${imageIndex}`"
                        class="pa-2"
                        outlined
                        tile
                        v-html="image.imageUrlHtml"
                      />
                      <!-- eslint-enable vue/no-v-html -->
                    </template>
                  </div>
                </div>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'QuestionView',
  inject: ['Names'],
  data() {
    return {
      question: {
        title: '',
        level: '',
        description: '',
        originalDescription: '',
        rightAnswerComment: '',
        topicId: null,
        questionImages: {
          imageUrl: '',
          imageUrlHtml: '',
        },
      },
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
    questionId() {
      return this.$route.params.questionId;
    },
  },
  async created() {
    try {
      this.question = await this.getQuestion(this.testId, this.questionId);
    } catch (error) {
      this.$di.notify.errorHandler(error);
    }
  },
  methods: {
    async getQuestion(testId, questionId) {
      const {
        title, level, description, rightAnswerComment,
      } = this;

      const payload = {
        testId, questionId, title, level, description, rightAnswerComment,
      };

      return this.$di.api.Talent.questionGet(payload);
    },

    async removeQuestion(testId, questionId) {
      try {
        await this.$di.api.Talent.questionDelete({ testId, questionId });
        this.$di.notify.snackSuccess('Вопрос успешно удалён');

        this.$router.push({
          name: this.Names.R_TALENT_TEST_TOPIC_QUESTIONS,
          params: { testId: this.testId, topicId: this.topicId },
        });
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
  },
};
</script>
